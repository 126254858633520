import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import {StaticImage} from "gatsby-plugin-image";

export default function Technicians() {
    return (
        <LayoutNew>
            <Helmet>
                {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                <title>Technicians 💜🐄</title>
            </Helmet>
            <section className="px-4 py-8 mx-auto font-light prose prose-lg">
                <StaticImage
                    layout="constrained"
                    src="images/Technicians.png"
                    alt="Technicians"
                />
                <br></br><br></br>
                <h1>Technicians</h1>
                <p>As it stands right now, we are in the middle of an industry shift that hasn’t fully ironed out all
                    the issues yet. One in particular is the technicians. Let me explain.</p>
                <p>In order for Purple Cow to provide internet across the Atlantic provinces, we lease lines from other
                    internet service providers. As part of this, we are required to use the technicians provided by the
                    company we lease these lines from.</p>
                <p>This arrangement is governed by a CRTC ruling designed to make internet prices more competitive for
                    Canadians. Unfortunately, the ruling presents some challenges that don’t align all parties’
                    incentives—particularly when it comes to technicians. It’s also worth noting that the ruling
                    prohibits us from naming the provider we lease from to avoid harming their brand—so I’ll refer to
                    them as the “Big Guy.”</p>
                <p>As you know, we offer the same internet service as the Big Guys at half the price, and
                    understandably, they aren’t excited about sharing their network with us while we offer a discount.
                    Under the CRTC ruling, we’re required to use their technicians for most on-site connectivity issues.
                    While this is great in ways as it gives us access to a large network of technicians, it comes with
                    limitations. For example, they are not required to allow direct access to the technician's
                    schedules, phone numbers, or emails, and instead, we rely solely on the Big Guy’s ticketing system,
                    which often pushes on a 24-hour response time.</p>
                <p>The Big Guy isn’t particularly incentivized to offer a great experience for our customers, since many
                    of them are switching from their service to ours. While they’re required to offer the same
                    availability of technician appointments, small touches like texting or calling ahead aren’t
                    mandatory—although some techs go out of their way to do so.</p>
                <p>A larger issue is that these technicians are compensated differently for a Purple Cow install
                    compared to a Big Guy install. For us, they’re only paid to ensure the coax line is active, but they
                    aren’t required to install the modem or help with setup. When they install for their own customers,
                    they’re paid to handle all of these steps. Even when we offer to pay extra for this level of
                    service, the Big Guy declines. As a result, some techs prioritize Big Guy installs where they’ll
                    earn more.</p>
                <p>Despite these challenges, most of the time, working with the Big Guy’s techs isn’t an issue. We’ve
                    heard plenty of stories of techs going above and beyond for our customers. But, due to the Big Guy’s
                    reluctance to help out on these small touches and the differences in compensation, it is often hard
                    to ensure your competitors' technicians are going to give everyone the magical experience they
                    deserve. (Of course this will only be the case until one day in the future if we build our own fiber
                    lines. :blush:)</p>
                <p>We’re constantly working with the Big Guy to improve the experience for you and the rest of the herd.
                    We’ll keep fighting for you, because we believe the Nova Scotian norm is not good enough for you. By
                    being in the herd, you are sending a powerful message to the Big Guys that enough is enough and that
                    fair internet pricing is something you and everyone else deserves! Thank you!</p>
                <p>Bradley Farquhar<br/>CEO</p>
            </section>
        </LayoutNew>
    )
}
